<template>
  <div>
    <div>
      <img src="https://water-1302249616.cos.ap-nanjing.myqcloud.com//2021-11-16/d96fbb445cd441a8ba6d396c68fc90ed-banner.png" alt style="width: 100%;">
    </div>
    <div class="tit-i">
      <img src="../assets/Group.png" alt>
      <h3>{{ data.title }}</h3>
      <img src="../assets/Group Copy.png" alt>
      <h5>
        <span>SERVICE</span>
      </h5>
    </div>
    <div class="ql-editor" style="width:60vw;margin-left:20vw;" v-html="data.detail" />
  </div>
</template>
<script>
import { details } from '../api/pc/home'
export default {
  data() {
    return {
      data: []
    }
  },
  created() {
    console.log(this.$route)
    details(this.$route.query.id)
      .then(res => {
        console.log('res', res)
        // res.data.item.parentDOS[1].subitemEntities.push(res.data.item.parentDOS[1].subitemEntities[0])
        this.data = res.data.subitem
      })
      .catch(e => {
        console.error(e)
      })
    // for (let i = 0; i < this.list.length; i++) {
    //   const item = this.list[i];
    //   console.log(item);
    // }
  }
}
</script>
<style  lang="scss" scoped>
.tit-i {
  padding: 20px 0;
  text-align: center;
  padding: 50px 0;
  padding-bottom: 0;
  line-height: 36px;
  overflow: hidden;
  h3 {
    font-size: 30px;
    color: #002;
    margin-bottom: 0px;
    display: inline-block;
  }
  h5 {
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    font-family: Arial;
    margin-top: 10px;
    margin-bottom: 0px;
    span {
      color: #e4392a;
    }
  }
  em {
    width: 45px;
    border-bottom: 1px solid #7f7f90;
    display: inline-block;
  }
}
.ql-editor {
  text-align: center;
  line-height: 50px;
  // margin-top: -65px;
}
</style>
<style lang="scss">
.ql-editor{
 img {
        max-width: 100%;
      }
}

</style>
